import styled from "styled-components";

export const Modal = styled.section`
  padding: 10px 10px 0 10px;
  width: 25%;
  background-color: #f2f2f1;
  height: 100px;
  align-items: end;
  align-content: end;
  align-self: flex-end;
  position: fixed;
  bottom: 0%;
  right: 2%;
  &:hover {
    opacity: 0.75;
  }

  border: 1px #aaa solid;
  border-radius: 1rem 1rem 0 0;
`;

export const FlexItem = styled.div`
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
`;
