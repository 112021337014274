import Button from "../Button";
import Dropdown from "../Dropdown";
import InputFiles from "../InputFile";
import TextField from "../TextField";
import { useState } from "react";
import "./form.css";
import axios from "axios";
import { FlexItem } from "../../styles/utils";
import TextArea from "../Textarea";
import "@aws-amplify/ui-react/styles.css";

const Forms = (props) => {
  const carrierSelect = [
    "Default",
    "Retail Brazil",
    "Claro Brazil",
    "TIM Brazil",
    "Vivo Brazil",
    "Claro Chile",
    "Claro Colombia",
    "Claro Peru",
    "Claro Puerto Rico",
    "Claro Guatemala",
    "Claro El Salvador",
    "Telcel Mexico",
    "Altan Mexico",
    "AT&T Mexico",
    "Retail Mexico",
    "Retail Argentina",
    "Claro Argentina",
    "Entel Chile",
    "Movistar Chile",
    "WOM Chile",
    "Retail El Salvador",
    "Retail Uruguay",
    "Movistar Mexico",
    "Retail Guatemala",
    "Retail Colombia",
  ];

  const environmentSelect = ["prod", "debug", "stage"];
  const osSelect = ["Default", "12", "13", "14", "15"];

  const campaignSelect = ["Default", "VALENTINES", "FATHERS", "MOTHERS"];
  const localeSelect = [
    "Default",
    "af-ZA",
    "ar-AE",
    "ar-BH",
    "ar-DZ",
    "ar-EG",
    "ar-IQ",
    "az-AZ",
    "es-ES",
    "ar-JO",
    "ar-KW",
    "ar-LB",
    "ar-LY",
    "ar-MA",
    "ar-OM",
    "ar-QA",
    "ar-SA",
    "ar-SY",
    "ar-TN",
    "ar-YE",
    "be-BY",
    "bg-BG",
    "bs-BA",
    "ca-ES",
    "cs-CZ",
    "cy-GB",
    "da-DK",
    "de-AT",
    "de-CH",
    "de-DE",
    "de-LI",
    "de-LU",
    "dv-MV",
    "el-GR",
    "en-AU",
    "en-BZ",
    "en-CA",
    "en-CB",
    "en-GB",
    "en-IE",
    "en-JM",
    "en-NZ",
    "en-PH",
    "en-TT",
    "en-US",
    "en-ZA",
    "en-ZW",
    "es-AR",
    "es-BO",
    "es-CL",
    "es-CO",
    "es-CR",
    "es-DO",
    "es-EC",
    "es-GT",
    "es-HN",
    "es-MX",
    "es-NI",
    "es-PA",
    "es-PE",
    "es-PR",
    "es-PY",
    "es-SV",
    "es-UY",
    "es-VE",
    "et-EE",
    "eu-ES",
    "fa-IR",
    "fi-FI",
    "fo-FO",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-FR",
    "fr-LU",
    "fr-MC",
    "gl-ES",
    "gu-IN",
    "he-IL",
    "hi-IN",
    "hr-BA",
    "hr-HR",
    "hu-HU",
    "hy-AM",
    "id-ID",
    "is-IS",
    "it-CH",
    "it-IT",
    "ja-JP",
    "ka-GE",
    "kk-KZ",
    "kn-IN",
    "ko-KR",
    "kok-IN",
    "ky-KG",
    "lt-LT",
    "lv-LV",
    "mi-NZ",
    "mk-MK",
    "mn-MN",
    "mr-IN",
    "ms-BN",
    "ms-MY",
    "mt-MT",
    "nb-NO",
    "nl-BE",
    "nl-NL",
    "nn-NO",
    "ns-ZA",
    "pa-IN",
    "pl-PL",
    "ps-AR",
    "pt-BR",
    "pt-PT",
    "qu-BO",
    "qu-EC",
    "qu-PE",
    "ro-RO",
    "ru-RU",
    "sa-IN",
    "se-FI",
    "se-NO",
    "se-SE",
    "sk-SK",
    "sl-SI",
    "sq-AL",
    "sr-BA",
    "sr-SP",
    "sv-FI",
    "sv-SE",
    "sw-KE",
    "syr-SY",
    "ta-IN",
    "te-IN",
    "th-TH",
    "tl-PH",
    "zh-HK",
    "zh-MO",
    "zh-SG",
    "zu-ZA",
    "tn-ZA",
    "tr-TR",
    "tt-RU",
    "uk-UA",
    "ur-PK",
    "uz-UZ",
    "vi-VN",
    "xh-ZA",
    "zh-CN",
    "zh-TW",
  ];

  const [product, setProduct] = useState("");
  const [model, setModel] = useState("");
  const [sku, setSku] = useState("");
  const [os, setOS] = useState("");
  const [cmf, setCmf] = useState("");
  const [imei, setImei] = useState("");
  const [jsonProduct, setJsonProduct] = useState();
  const [jsonExtras, setJsonExtras] = useState();
  const [carrier, setCarrier] = useState("");
  const [environment, setEnvironment] = useState("");
  const [campaign, setCampaign] = useState("");
  const [locale, setLanguage] = useState("");
  const [screensaver, setScreensaver] = useState("");
  const [media, setMedia] = useState("");
  const [sizzle, setSizzle] = useState("");

  const save = (event) => {
    console.log("CLICK");
    const url = "https://api.moto-demomode-cloud.com/posts";
    // const url = "http://localhost:3030/posts";
    event.preventDefault();
    props.registeredProducts({
      product,
      model,
      sku,
      os,
      jsonProduct,
      jsonExtras,
      carrier,
      environment,
      campaign,
      locale,
      imei,
      screensaver,
      media,
      sizzle,
    });
    axios
      .post(
        url,
        {
          product,
          model,
          sku,
          os,
          jsonProduct,
          jsonExtras,
          carrier,
          environment,
          campaign,
          locale,
          imei,
          screensaver,
          media,
          sizzle,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("Form sended", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div onSubmit={save} className="forms">
        <h2>New Product</h2>
        <FlexItem>
          <TextField
            required={true}
            label="Product "
            tooltip="ProductInfo"
            tooltipDescription="Insert the name of new product. Ex: Dubai"
            placeholder="Enter the Product name"
            value={product}
            wordChanged={(value) => setProduct(value)}
          />

          <TextField
            required={true}
            label="Model "
            tooltip="ModelInfo"
            tooltipDescription="Insert the name of model. Ex: Moto Edge 40"
            placeholder="Enter the name of the Model"
            value={model}
            wordChanged={(value) => setModel(value)}
          />
          <TextField
            required={true}
            label="SKU "
            tooltip="SKUInfo"
            tooltipDescription="SKU it's a unique code to identify the product. Ex: XT-2025"
            placeholder="Enter the SKU"
            value={sku}
            wordChanged={(value) => setSku(value)}
          />
        </FlexItem>
        <FlexItem>
          <Dropdown
            required={true}
            items={environmentSelect}
            label="Environment"
            value={environment}
            wordChanged={(value) => setEnvironment(value)}
          />
          <Dropdown
            required={true}
            items={osSelect}
            label="OS"
            value={os}
            wordChanged={(value) => setOS(value)}
          />
          <Dropdown
            label="Carrier"
            items={carrierSelect}
            value={carrier}
            required={true}
            wordChanged={(value) => setCarrier(value)}
          />
        </FlexItem>
        <FlexItem>
          <Dropdown
            label="Campaign"
            items={campaignSelect}
            value={campaign}
            required={true}
            wordChanged={(value) => setCampaign(value)}
          />
          <Dropdown
            label="Language"
            items={localeSelect}
            value={locale}
            required={true}
            wordChanged={(value) => setLanguage(value)}
          />
          <TextField
            label="IMEI "
            type="number"
            tooltip="IMEIInfo"
            tooltipDescription="IMEI is a unique code with 15 numbers. Ex: 355747236310170"
            placeholder="Optional"
            value={imei}
            wordChanged={(value) => setImei(value)}
          />
        </FlexItem>
        <TextField
          label="CMF "
          placeholder="Optional"
          tooltip="CMFInfo"
          tooltipDescription="CMF defines a specific characteristic of a product. Ex: "
          value={cmf}
          wordChanged={(value) => setCmf(value)}
        />
        <FlexItem>
          <TextArea
            label="Json Product "
            placeholder="Json Product"
            tooltip="JsonProductInfo"
            tooltipDescription="JSON Product define localization the KSP(core) and SIZZLE."
            value={jsonProduct}
            wordChanged={setJsonProduct}
          />
          <TextArea
            label="Json Extras "
            tooltip="JsonExtrasInfo"
            tooltipDescription="JSON Extras define other assets KSP(core) and SIZZLE."
            placeholder="Optional"
            value={jsonExtras}
            wordChanged={setJsonExtras}
          />
        </FlexItem>
        <FlexItem>
          <InputFiles
            required={true}
            label="Media"
            id="media"
            setFile={(value) => setMedia(value)}
          />
          <InputFiles
            required={true}
            label="Screensaver"
            id="screensaver"
            setFile={(value) => setScreensaver(value)}
          />
        </FlexItem>
        <FlexItem>
          <InputFiles
            required={true}
            label="KSP"
            id="sizzle"
            setFile={(value) => setSizzle(value)}
          />
          <InputFiles required={true} label="Extras" id="kspExtras" />
        </FlexItem>
        {/* <JSONValidator /> */}

        <Button value="Register" onClick={save}>
          Register
        </Button>
      </div>
    </div>
  );
};

export default Forms;
