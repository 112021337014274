import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import InputFiles from "../../components/InputFile";
import { Container, Forms } from "./style";
import { useState, useEffect } from "react";
import axios from "axios";

const NewCampaign = (props) => {
  const [campaignNames, setCampaignNames] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [productNames, setProductNames] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const model = useState("");
  const sku = useState("");
  const os = useState("");

  const save = (event) => {
    event.preventDefault();
    props.registeredProducts({
      selectedProduct,
      model,
      sku,
      os,
    });
    console.log("Form sended => ", selectedProduct, model, sku, os);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.moto-demomode-cloud.com/posts"
        );
        const data = response.data;
        const concatProdLocale = data.map(
          (each) => `${each.product}-${each.locale}`
        );
        setProductNames(concatProdLocale);
      } catch (error) {
        console.error("Error getting products:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.moto-demomode-cloud.com/campaigns"
        );
        const campaigns = response.data;
        const namesCampaign = campaigns.map((campaign) => campaign.campaign);
        setCampaignNames(namesCampaign);
      } catch (error) {
        console.error("Error getting campaigns:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <h3>New Campaign</h3>
      <Forms>
        <div onSubmit={save} className="forms">
          <h2>Enter the data in the selected product</h2>
          <Dropdown
            label="Product"
            items={productNames}
            value={selectedProduct}
            required={true}
            wordChanged={(value) => setSelectedProduct(value)}
          />
          <Dropdown
            label="Campaign"
            items={campaignNames}
            value={selectedCampaign}
            required={true}
            wordChanged={(value) => setSelectedCampaign(value)}
          />

          <InputFiles required={true} label="Screensaver" id="screensaver" />

          <Button value="Register" />
        </div>
      </Forms>
    </Container>
  );
};

export default NewCampaign;
