import { Container } from "./style";

const NewCarrier = () => {
  return (
    <Container>
      <h3>New Carrier</h3>
    </Container>
  );
};

export default NewCarrier;
