import { Container } from "./style";

const Home = () => {
  return (
    <Container>
      <h4>Home</h4>
    </Container>
  );
};

export default Home;
