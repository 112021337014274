import List from "../../components/List";
import { Container } from "./style";

const ListProduct = () => {
  return (
    <Container>
      <List />
    </Container>
  );
};

export default ListProduct;
